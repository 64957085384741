.currentWinner {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  font-size: 80px;
  color: white;
  display: flex;
  flex: 1;
}

.promotion {
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  display: flex;
  padding: 92px;
  width: 100%;
  flex: 1;
}

.promotion .title {
  margin-bottom: 24px;
}

.promotion .hosted-by {
  font-size: 60px;
  opacity: 0.4;
}

.promotion .prize {
  font-size: 60px;
}

.promotion .prize-img {
  position: absolute;
  width: 1130px;
  right: -380px;
  top: -160px;
}

.winner {
  background: white;
  flex-direction: row;
  align-items: center;
  position: relative;
  color: black;
  display: flex;
  width: 100%;
  flex: 1;
}

.winner .user-img {
  width: 540px;
}

.winner .info {
  justify-content: space-between;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  padding: 80px;
  height: 100%;
}

.winner .info .name {
  margin-bottom: 24px;
  font-weight: 800;
  font-size: 90px;
}

.winner .info .completed {
  font-size: 60px;
  opacity: 0.4;
}

.winner .info .languages {
  font-size: 60px;
}

.winner .score {
  border-bottom-left-radius: 95px;
  border-top-left-radius: 95px;
  justify-content: center;
  box-sizing: border-box;
  background: #00B1FF;
  align-items: center;
  position: absolute;
  font-size: 120px;
  min-width: 400px;
  font-weight: 700;
  padding: 0 42px;
  color: white;
  display: flex;
  height: 190px;
  right: 0;
}
