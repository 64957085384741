.container {
  font-family: Montserrat, sans-serif;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  display: grid;
  transform-origin: top left;
  min-width: 3840px;
  min-height: 2160px;
  height: 100vh;
  width: 100vw;

}

#particles-js {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: black;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.teaser {
  font-family: Montserrat, sans-serif;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  font-size: 60px;
  color: white;
  display: flex;
  height: 100%;
  flex: 1;
}

.teaser .title {
  margin-bottom: 120px;
  opacity: 0.4;
}

.teaser .time {
  text-align: center;
  font-size: 100px;
  font-weight: 900;
}

.teaser .hosted-by {
  text-align: center;
  margin-top: 120px;
  opacity: 0.4;
}

.prize-container {
  font-family: Montserrat, sans-serif;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-weight: 900;
  font-size: 60px;
  color: white;
  display: flex;
  height: 100%;
  flex: 1;
}

.prize-container .title {
  margin-bottom: 100px;
}

.prize-container img {
  width: 800px;
}

.prize-container .name {
  font-weight: 900;
  margin-top: 100px;
  opacity: 0.4;
}

.second-prize .title {
  font-weight: 600;
}

.second-prize .name {
  font-weight: 600;
}

.third-prize .title {
  font-weight: 400;
  margin-bottom: 8px;
}

.third-prize .name {
  font-weight: 400;
  margin-top: -32px;
}

.second-prize img {
  width: 420px;
}

.third-prize img {
  width: 420px;
}
