.score {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  font-size: 100px;
  color: white;
  display: flex;
  flex: 1;
}

.score .title {
  margin-bottom: 24px;
  text-align: center;
  opacity: 0.4;
}

.score .value {
  font-size: 320px;
  font-weight: 900;
  transition: 0.8s;
}

.score .left {
  box-sizing: border-box;
  text-align: center;
  margin-top: 24px;
  padding: 0 42px;
  font-size: 60px;
  opacity: 0.4;
}
