.leaderboard {
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  font-size: 60px;
  color: white;
  padding: 90px;
  display: flex;
  flex: 1;
}

.col-position {
  text-overflow: ellipsis;
  box-sizing: border-box;
  white-space: nowrap;
  padding-left: 24px;
  flex-wrap: nowrap;
  overflow: hidden;
  flex-shrink: 0;
  flex: 1.4;
}
.col-username {
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding-right: 24px;
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
  flex-shrink: 0;
  flex: 6;
}
.col-languages {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
  flex-shrink: 0;
  flex: 6;
}
.col-completed {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-wrap: nowrap;
  text-align: right;
  overflow: hidden;
  flex-shrink: 0;
  flex: 2;
}
.col-score {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-wrap: nowrap;
  text-align: right;
  overflow: hidden;
  flex-shrink: 0;
  flex: 3;
}

.leaderboard .header {
  flex-direction: row;
  display: flex;
  opacity: 0.4;
  width: 100%;
}

.leaderboard .competitors {
  flex-direction: column;
  margin-top: 60px;
  display: flex;
  width: 100%;
  flex: 1;
}

.competitor {
  padding-bottom: 24px;
  flex-direction: row;
  align-items: center;
  position: relative;
  display: flex;
  width: 100%;
  padding-bottom: 24px;
}

.competitor:nth-child(2) { opacity: 0.9; }
.competitor:nth-child(3) { opacity: 0.8; }
.competitor:nth-child(4) { opacity: 0.7; }
.competitor:nth-child(5) { opacity: 0.6; }
.competitor:nth-child(6) { opacity: 0.5; }
.competitor:nth-child(7) { opacity: 0.4; }
.competitor:nth-child(8) { opacity: 0.3; }

.status {
  background: white;
  position: absolute;
  border-radius: 50%;
  transition: 0.5s;
  height: 28px;
  opacity: 0.4;
  left: -42px;
  width: 28px;
}

.online {
  background-color: greenyellow;
  transition: 0.5s;
  opacity: 1;
}
