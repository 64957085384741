.timeRemaining {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  font-size: 100px;
  color: white;
  display: flex;
  flex: 1;
}

.timeRemaining .title {
  margin-bottom: 60px;
  text-align: center;
  opacity: 0.4;
}

.timeRemaining .time {
  font-size: 160px;
  font-weight: 900;
}

.timeRemaining .left {
  box-sizing: border-box;
  text-align: center;
  margin-top: 70px;
  font-size: 60px;
  opacity: 0.4;
}
